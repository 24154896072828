import React from "react"
import { styled } from "@mui/system"
import { Typography } from "@mui/material"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import GridLines from "../../gridLines"

const NavigationWrapper = styled("div")(({ theme }) => ({
  maxWidth: "1280px",
  margin: "0 auto",
  display: "flex",
  flexDirection: "row",
  rowGap: "100px",
  alignContent: "center",
  alignItems: "center",
  justifyContent: "center",
  boxSizing: "border-box",
  userSelect: "none",
  "@media (max-width:1025px)": {
    width: "100%",
  },
  "@media (max-width:576px)": {
    flexDirection: "column",
    rowGap: "28px",
  },
}))

const BlockWrapper = styled("div")({
  position: "relative",
  borderRadius: "2px",
  width: "100%",
  // height: "433px",
  "&:nth-of-type(2)": {
    marginLeft: "100px",
    "@media (max-width:1280px)": {
      marginLeft: "52px",
    },
    "@media (max-width:1025px)": {
      marginLeft: "32px",
    },
  },
  "& a": {
    textDecoration: "none",
  },
  "& div": {
    // height: "inherit",
    width: "inherit",
  },
  "@media (max-width:1300px)": {
    width: "100%",
    // height: "360px",
    margin: "0 28px",
  },
  "@media (max-width:1025px)": {
    // width: "44vw",
    // height: "320px",
    margin: "0 28px",
  },
  "@media (max-width:800px)": {
    // width: "44vw",
    // height: "280px",
    margin: "0 28px",
  },
  "@media (max-width:576px)": {
    width: "90vw",
    // minHeight: "72.2vw",
    "&:nth-of-type(2)": {
      marginLeft: "2vw",
      marginBottom: "0px"
    },
    margin: "2vw 2vw",
    "& div": {
      width: "90vw",
    },
  },
})

const Title = styled(Typography)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  maxWidth: "74.4%",
  maxHeight: "160px",
  marginTop: "auto",
  marginBottom: "auto"
})

const TextContainer = styled("div")({
  position: "absolute",
  left: 0,
  right: 0,
  marginLeft: "auto",
  marginRight: "auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "72%",
  height: "100%",
  justifyContent: "center",
  top: "0%",
})

const Button = styled("button")(({ theme }) => ({
  ...theme.buttons.transparentButton,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  letterSpacing: "-0.01em",
  width: "247px",
  height: "42px",
  marginBottom: "10%",
  padding: "0.5em",
  "@media (max-width:1024px)": {
    width: "26vw",
    // marginTop: "3vw",
  },
  "@media (max-width:576px)": {
    width: "48.7vw",
    height: "8.3vw",
    // marginTop: "6.28vw",
  },

  transition:"all 0.7s",
  "&:hover": {
    background: "white",
    color: "#0870E0",
    "& span": {
      color:"#0870E0",
    },
  },

  "&:focus": {
    background:"white",
    color:"#0870E0",
    "& span": {
      color:"#0870E0",
    },
  },

  "& span": {
    color: "white",
    "&:hover": {
      color:"#0870E0",
    },
    "&:focus": {
      color: "#0870E0",
    },
  },

}))

const NavigationInvestorsFounders = ({ data }) => {
  return (
    <>
      <GridLines
        styles={{
          height: 144,
          heightMob: 60,
          marginTop: 29,
          marginTopMob: 25,
          marginBottom: 28,
          marginBottomMob: 25,
          color: "#F8F8F8",
        }}
      />
    <NavigationWrapper>
      <BlockWrapper>
        <GatsbyImage image={data.edges[0].node.childImageSharp.gatsbyImageData} alt="founders-background" style={{borderRadius: "2px"}}/>
        <TextContainer>
        <Title>
            <Typography variant="investorsFoundersTitle">
              Our portfolio keeps growing as we partner with new founders
            </Typography>
          </Title>
          <Button>
            <Link to="/founders">
              <Typography variant="investorsFoundersButton">
                join graphene family
              </Typography>
            </Link>
          </Button>
        </TextContainer>
      </BlockWrapper>
      <BlockWrapper>
        <GatsbyImage image={data.edges[1].node.childImageSharp.gatsbyImageData} alt="investors-background" style={{borderRadius: "2px"}}/>
        <TextContainer>
        <Title>
            <Typography variant="investorsFoundersTitle">
            We partner with investors to understand venture capital market and ecosystem
            </Typography>
          </Title>
          <Button>
            <Link to="/investors">
              <Typography variant="investorsFoundersButton">
              check our experience
              </Typography>
            </Link>
          </Button>
          </TextContainer>
      </BlockWrapper>
    </NavigationWrapper>
    <GridLines
        styles={{
          height: 144,
          heightMob: 30,
          marginTop: 28,
          marginTopMob: 50,
          marginBottom: 0,
          marginBottomMob: 0,
          color: "#F8F8F8",
        }}
      />
    </>

  )
}

export default NavigationInvestorsFounders
