import * as React from "react"
import Seo from "../components/seo"
import Portfolio from "./../components/portfolio"
import GlobeComponent from "../components/indexPage/globeComponent"
import GlobalTeam from "./../components/globalTeam"
import NavigationInvestorsFounders from "./../components/indexPage/InvestorsAndFounders"
import News from "./../components/indexPage/news"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import GridLines from "../components/gridLines"

const IndexPage = ({ data }) => {
  return (
    <Layout headerDark={true} firstScreen={true}>
      <Seo
        title="Graphene Ventures"
        onlyTitle={true}
        description={`Venture capital firm focused on early-stage tech companies in enterprise software and consumer technology.`}
      />
      <GridLines
        styles={{
          height: 144,
          heightMob: 60,
          marginBottom: 21,
          marginBottomMob: 47,
          color: "#F8F8F8",
        }}
      />
      <Portfolio data={data} />
      <GlobeComponent />
      <GlobalTeam data={data.strapiOurTeamOrder} />
      <NavigationInvestorsFounders data={data.allFile} />
      <News data={data.allStrapiNewsItem} />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query IndexPageQuery {
    strapiOurTeamOrder {
      order {
        member_1 {
          position
          name
          photo {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        member_2 {
          position
          name
          photo {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        member_3 {
          position
          name
          photo {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        member_4 {
          position
          name
          photo {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        member_5 {
          position
          name
          photo {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
    allFile(
      filter: { name: { in: ["foundersBackground", "investorsBackground"] } }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    allStrapiNewsItem(limit: 6) {
      edges {
        node {
          strapi_id
          source_link
          source_name
          blog_type {
            Type
          }
          title
          publishedAt(formatString: "YYYY-MM-DD[T]HH:mm")
          publication_date(formatString: "YYYY-MM-DD[T]HH:mm")
        }
      }
    }
    allStrapiCompany(sort: { fields: rank, order: ASC }) {
      edges {
        node {
          strapi_id
          name
          color
          description
          year_founded
          location
          stage
          logo {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, placeholder: BLURRED)
              }
              publicURL
            }
          }
          preview_images {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  }
`
