import React from "react"
import { styled } from "@mui/system"
import { Typography } from "@mui/material"
import { useMediaQuery } from "@mui/material"
import { Link } from "gatsby"


const NewsWrapper = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.grey,
  width: "100%",
  margin: "0 auto",
  display: "flex",
  flexDirection: "column",
  alignContent: "center",
  alignItems: "center",
  justifyContent: "center",
  boxSizing: "border-box",
}))

const Wrapper = styled("div")({
  // maxWidth: "1440px",
  maxWidth: "1280px",
  margin: "64px 0 76px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  "@media (max-width:1336px)": {
    width: "100%",
    padding: "0 28px",
  },
  "@media (max-width:576px)": {
    margin: "64px 40px 0",
    padding: "0 40px",
  },
})

const NewsBlock = styled("div")({
  marginTop: "35px",
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  gridColumnGap: "50px",
  // flexWrap: "wrap",
  // justifyContent: "space-between",
  "@media (max-width:1025px)": {
    gridColumnGap: "30px",
  },
  "@media (max-width:576px)": {
    gridTemplateColumns: "1fr",
  }
})

const OneNews = styled("div")({
  maxWidth: "406px",
  marginBottom: "105px",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  // justifyContent: "space-between",
  "@media (max-width:1225px)": {
    maxWidth: "30vw",
  },
  "@media (max-width:1025px)": {
    maxWidth: "275px",
  },
  "@media (max-width:860px)": {
    maxWidth: "30vw"
  },
  "@media (max-width:576px)": {
    maxWidth: "100%"
  }
})

const Title = styled("div")(({ theme }) => ({
  display: "flex",
  marginBottom: "18px",
  "@media (max-width:576px)": {
    marginBottom: "21px",
  },
  "& span": {
    marginRight: "18px",
    textTransform: "uppercase",
  },
  "& hr": {
    color: theme.palette.secondary.blue,
    width: "100%",
    height: "4px",
    backgroundColor: theme.palette.secondary.blue,
    margin: "0.5em auto",
  },
}))

const LinkBox = styled(Link)({
  "&:hover": {
    "div > span:first-of-type": {
      color: "#0870E0",
    },
  },
})

const News = ({ data }) => {
  const isMobile = useMediaQuery("(max-width: 576px)")
  const clean_news = []
  data.edges.forEach(news => {
    var newsLink = news.node.title.toLowerCase()
    .replace('.', '')
    .replace(/ /g,'-')
    .replace(/[^\w-]+/g,'') + `-${news.node.strapi_id}`
    var timeNow = new Date()
    var timeNews = new Date(news.node.publication_date)
    var delta_time = Math.round(Math.abs((timeNow - timeNews) / 1000 / 60 / 60))
    var timeString
    if (delta_time < 24) {
      if (delta_time === 1 || delta_time === 0){
        timeString = "1 hour ago"
      } else {
        timeString = delta_time.toString() + " hours ago"
      }
    } else {
      timeString = timeNews.toLocaleString('en-us', { day: "numeric", month: 'short', year: "numeric" })
    }
    clean_news.push({
      type: news.node.blog_type.Type,
      title: news.node.title,
      source: news.node.source_name,
      time: timeString,
      link: newsLink,
    })
  })

  let previewData = clean_news

  if (isMobile) {
    previewData = clean_news.slice(0, 4)
  }

  return (
    <NewsWrapper>
      <Wrapper>
        <Link to="/blog" style={{textDecoration: "none"}}>
          <Typography variant="newsAndUpdates">news & updates</Typography>
        </Link>
        <NewsBlock>
          {previewData.map(oneNew => (
            <LinkBox
              key={Math.random() + "keynews"}
              to={oneNew.link}
              style={{ textDecoration: "none" }}
            >
              <OneNews>
                <Title>
                  <Typography variant="newsTitle">{oneNew.type}</Typography>
                  <hr />
                </Title>
                <Typography variant="newsBody">{oneNew.title}</Typography>
                <Typography variant="newsFooter">
                  {oneNew.time} { oneNew.source ? " - " : null}
                  <span style={{ fontWeight: 700 }}>{oneNew.source}</span>
                </Typography>
              </OneNews>
            </LinkBox>
          ))}
        </NewsBlock>
      </Wrapper>
    </NewsWrapper>
  )
}

export default News
