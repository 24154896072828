import React, {useState} from "react"
import { useMediaQuery, Typography } from "@mui/material"
import { styled } from "@mui/system"
import GridLines from "../../gridLines"
import { StaticImage } from "gatsby-plugin-image"
import EarthImage from "../../../static/images/EarthFullImage.png"

const Background = styled("div")({
  background: "linear-gradient(99.26deg, #0c152e 54.75%, #172856 84.66%)",
  "@media (max-width:576px)": {
    background: "linear-gradient(180deg, #0c152e 73.49%, #172856 100%)",
  },
})
const Wrapper = styled("div")({
  maxWidth: "1280px",
  margin: "0 auto",
  display: "flex",
  flexDirection: "row",
  alignContent: "center",
  alignItems: "center",
  maxHeight: "100%",
  height: 652,
  pointerEvents: "none",
  "@media (max-width:1280px)": {
    height: "50.9375vw",
  },
  "@media (max-width:576px)": {
    margin: 0,
    height: "auto",
    alignItems: "flex-start",
  },
})

const MobileWrapper = styled("div")({
  // margin: "0 20px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
})

const GlobusContainer = styled("div")({
  zIndex: 3,
  position: "relative",
  width: "50%",
  height: "100%",
  overflow: "hidden",
  "@media (max-width:576px)": {
    flexGrow: 1,
    width: "100%",
  },
})

const MainText = styled(Typography)({
  fontFamily: "Heiti TC",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: 60,
  lineHeight: "120%",
  color: "#FFFFFF",
  maxWidth: "734px",
  "@media (max-width:1280px)": {
    fontSize: "4.6875vw",
  },
  "@media (max-width:576px)": {
    fontSize: "7.17vw",
    marginTop: "14.1vw",
    padding: "0 40px",
    marginBottom: "3.07vw",
  },
})

const BodyText = styled(Typography)({
  fontFamily: "Merriweather",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: 22,
  lineHeight: "190%",
  color: "#FFFFFF",
  marginRight: 10,
  marginTop: 26,
  maxWidth: "620px",
  "@media (max-width:1280px)": {
    fontSize: "1.5625vw",
    marginRight: "3.9vw",
    marginTop: "2vw",
  },
  "@media (max-width:576px)": {
    fontSize: "3.58vw",
    padding: "0 40px",
    marginTop: "7.5vw",
    marginBottom: "12.3vw",
    marginRight: 0,
  },
})

const DummyContainer = styled("div")({
  width: "100%",
  // height: "612px",
  height: "83.3%",
  "@media (max-width:576px)": {
    height: "67vw",
    // padding: "0 40px",
  },
})

const GlobusVideo = styled("video")({
  position: "absolute",
  top: "-3%",
  left: "-16.56%",
  // width: "799px",
  // height: "799px",
  width: "124.8%",
  height: "122.5%",
  marginBottom: 0,
  display: "flex",
  flexGrow: 1,
  "@media (max-width:1024px)": {
    left: "-12%",
  },
  "@media (max-width:576px)": {
    width: "126.92vw",
    height: "126.92vw",
    top: "-20vw",
    left: "-12.5vw",
  },
})

const GradientLine = styled("div")({
  zIndex: 10,
  position: "absolute",
  background:
    "linear-gradient(90deg, rgba(227, 227, 227, 0) 0%, #E3E3E3 51.56%, rgba(227, 227, 227, 0) 100%)",
  width: "calc(100% - 50px)",
  height: "1px",
  bottom: "16.71%",
  "@media (max-width:1024px)": {
    width: "calc(100% - 40px)",
    left: "20px",
    bottom: 0,
  },
})

const GlobusWrapper = styled("div")({
  position: "relative",
  width: "100%",
  height: "734px",
})

const GlobusVideoWrapper = styled("div")({
  position: "absolute",
  backgroundPosition: "center",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  zIndex: -1,
  overflow: "hidden",
  objectFit: "cover",
})

const GlobusFrame = styled("video")({
  width: "100vw",
  minHeight: "734px",
  minWidth: "1920px" /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */,
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
})

const TextContainer = styled("div")({
  position: "absolute",
  zIndex: 3,
  display: "flex",
  flexDirection: "column",
  maxHeight: "100%",
  minHeight: "350px",
  justifyContent: "center",
  width: "50%",
  height: "100%",
  color: "#FFFFFF",
  top: 0,
  left: "50%",
})

const ThumbnailWrap = styled("div")({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  zIndex: -1,
  overflow: "hidden",
})

const GlobeComponent = () => {
  const isMobile = useMediaQuery("(max-width: 576px)")
  // const [showPoster, setShowPoster] = useState(true)
  const thumbnailStyles = {
    width: "100vw",
    minHeight: "734px",
    minWidth: "1920px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  }

  return (
    <>
      <GridLines
        styles={{
          height: 87,
          heightMob: 30,
          marginTop: 37,
          marginTopMob: 24,
          color: "#F8F8F8",
          position: "relative",
          background: "white",
        }}
      />
      {isMobile ? (
        <Background>
          <Wrapper>
            <MobileWrapper>
              <MainText>
                Global presence to support international ambitions
              </MainText>
              <GlobusContainer>
                <DummyContainer />
                <GlobusVideo
                  title="globus"
                  src="https://admin.graphenevc.com/uploads/graphene_global_approach_720p_c356c6697b.mp4"
                  muted
                  autoPlay
                  loop
                  allowFullScreen
                ></GlobusVideo>
                <GradientLine />
                {/* <UnderlineDiv /> */}
              </GlobusContainer>
              <BodyText>
                Operating from US, Canada, Brazil and Saudi Arabia Graphene
                covers the US and other markets looking to team up with
                phenomenal founders.
              </BodyText>
            </MobileWrapper>
          </Wrapper>
        </Background>
      ) : (
        <>
          <GlobusWrapper>
            <GlobusVideoWrapper
            // style={{zIndex: showPoster ? -1 : 2 }}
            style={{backgroundImage: `url(${EarthImage})`}}
            >
              <ThumbnailWrap>
                <StaticImage
                  style={thumbnailStyles}
                  src={"../../../static/images/EarthFullImage.png"}
                  alt={""}
                />
              </ThumbnailWrap>
              <GlobusFrame
                // onLoad={() => setShowPoster(false)}
                src="https://admin.graphenevc.com/uploads/graphene_global_approach_v_2_1080p_1fa93f44f8.mp4"
                muted
                autoPlay
                loop
                allowFullScreen
                title="Graphene Global Approach v. 2"
              />
            </GlobusVideoWrapper>
            <TextContainer>
              <MainText>
                Global presence to support international ambitions
              </MainText>
              <BodyText>
                Operating from US, Canada, Brazil and Saudi Arabia Graphene
                covers the US and other markets looking <br />
                to team up with phenomenal founders.
              </BodyText>
            </TextContainer>
          </GlobusWrapper>
        </>
      )}
      <GridLines
        styles={{
          height: 87,
          heightMob: 27,
          marginBottom: 27,
          marginBottomMob: 25,
          color: "#F8F8F8",
          position: "relative",
          background: "white",
        }}
      />
    </>
  )
}

export default GlobeComponent
